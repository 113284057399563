export const publicDataSelector  = (state: any) => state.data.publicData;
export const userGroupsSelector  = (state: any) => state.auth.groups;
export const pathSelector = (state: any) => state.data.path;
export const isAuthSelector = (state: any) => state.auth.isAuthenticated;
export const uploadProgressSelector = (state:any)=>state.upload.uploadProgress;
export const uploadIsErrorSelector = (state: any) => state.upload.isError
export const isUploadingSelector = (state: any) => state.upload.isUploading
export const uploadSuccessModalSelector = (state:any)=>state.upload.successModal
export const ShareEmailModalOpenSelector = (state:any)=>state.upload.shareEmailModalOpen
export const ShareEmailModalEnabledSelector = (state:any)=>state.upload.shareEmailModalEnabled
export const userMailSelector = (state:any)=>state.auth.email
export const datasetEmailEditSelector = (state:any)=>state.data.editEmailAccess
export const isLoadingSelector = (state:any)=>state.auth.isLoading

