import React, {useEffect, useLayoutEffect, useState} from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { useDispatch, useSelector } from "react-redux";
import { setDefaultView } from "../../store/actions/dataActions";
import Publicdata from "../Publicdata/Publicdata";
import Privatedata from "../Privatedata/Privatedata";
import {
    capitalizeFirstLetter,
    filterDatasetsByGroups,
    generateUserAccessGroups,
    isUserHasAccess
} from "../../utils/utils";
import { CircularProgress } from "@material-ui/core";
import { logout } from "../../store/actions/authActions";
import Select from "react-select";
import countries from "../../data/aratzot.json";
import * as Types from '../../store/types/Types'
import {publicDataSelector} from "../../store/selectors/selectors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: "60px",
    },
    buttons: {
      display: "flex",
      width: "50px",
      justifyConter: "center",
      alignItems: "center",
      paddingBottom: "10px",
    },
    buttonsText: {
      color: "#7d7d7d",
      letterSpacing: "0.14px",
    },
    upButton: {
      marginLeft: "20px",
      cursor: "pointer",
    },
    datasetsTitle: {
      fontSize: "30px",
      fontWeight: "normal",
      textAlign: "left",
      marginBottom: "20px",
      opacity: "0.9",
    },
    selectContainer: {
      display: "flex",
      margin: "30px 0px",
    },
    select: {
      padding: "10px 30px",
      opacity: "0.9",
    },
    errorContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
      groupsSelectContainer:{
        minWidth:"180px"
      }
  })
);

const Datasets: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const userGroups = useSelector((state: any) => state.auth.groups);
  const error = useSelector((state: any) => state.data.error);
  const isLoading = useSelector((state: any) => state.auth.isLoading);
  const data = useSelector(publicDataSelector);
  const [currentDataset, setCurrentDataset] = useState("Public");
  const [currentGroups, setCurrentGroups] = useState([]);
  const [allowedGroups, setAllowedGroups] = useState<any>(null);


  //first create allowed group from user groups.
  useLayoutEffect(() => {
    if (userGroups) {
      setAllowedGroups(generateUserAccessGroups("read", userGroups));
    }
  }, [userGroups]);


  const handleUpButton = () => {
    dispatch(setDefaultView(currentDataset));
  };

  const handleOptionChange = (e: any) => {
      if (e){
          const groups = e.map((group:any)=>{
              return group.value
          })
          setCurrentGroups(groups)
          const viewByGroup = filterDatasetsByGroups(groups,data.data)
          // console.log('setting view by group')
          dispatch({type:Types.SET_CURRENT_VIEW_PUBLIC,payload:viewByGroup})
      } else {
          setCurrentGroups([])
          const viewByGroup = filterDatasetsByGroups([],data.data)
          dispatch({type:Types.SET_CURRENT_VIEW_PUBLIC,payload:viewByGroup})
      }
  };

  const handleLoginBtn = () => {
    dispatch(logout());
  };

   const generateGroupsOptions = () => {
    let defaultValue: any = [{value:"email", label:"Shared By Email"}];
    return defaultValue.concat(
      allowedGroups.map((group: any) => {
        return { value: group, label: capitalizeFirstLetter(group) };
      })
    );
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.datasetsTitle}>Datasets</h1>
      <div className={classes.selectContainer}>
        {allowedGroups && (
          <Select
            className={classes.groupsSelectContainer}
            classNamePrefix="react-select"
            options={generateGroupsOptions()}
            isRtl={false}
            isSearchable={true}
            defaultValue={generateGroupsOptions()}
            isMulti={true}
            onChange={(e: any) =>
               handleOptionChange(e)
            }
            maxMenuHeight={200}
          />
        )}
      </div>
      <div className={classes.buttons}>
        <p className={classes.buttonsText}>Name</p>
        <ArrowUpwardIcon
          className={classes.upButton}
          onClick={() => handleUpButton()}
        />
      </div>
      {isLoading && <CircularProgress />}

      {error && error.isError && (
        <div className={classes.errorContainer}>
          {error.txt}
          <button className="submitBtn" onClick={() => handleLoginBtn()}>
            Login
          </button>
        </div>
      )}
      {currentDataset === "Public" ? <Publicdata /> : <Privatedata />}
      {/*  {!isLoading && <Publicdata/>}*/}
    </div>

  );
};

export default Datasets;
