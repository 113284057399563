import Axios from 'axios';
import config from "../services/config.service";

const serverAddress = config.server.url

async function registerUser(userDetails: { email: String; password: String }) {
    try{
      const res = await Axios.post(
          `${serverAddress}/users/signup`,
          userDetails
      )
        return {status:res.status,data:res.data};
    }
    catch (e) {
        throw e;
    }
}
async function signInUser(userDetails: { email: String; password: String }) {
    try{
      const res = await Axios.post(
          `${serverAddress}/users/login`,
          userDetails
      )
        return {status:res.status,data:res.data};
    }
    catch (e) {
        throw e;
    }
}

async function logoutUser() {
    try{
        const res = await Axios.get(`${serverAddress}/users/logout`,{withCredentials:true})
        return true
    }
    catch (e) {
        return false
    }
}

export default {
    registerUser,
    signInUser,
    logoutUser
}