import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import "./Upload.css";
import { CircularProgress, TextField } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { ReactComponent as UploadSvg } from "../../imgs/upload.svg";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { uploadFilesPublic } from "../../store/actions/dataActions";
import {
  capitalizeFirstLetter,
  generateUserAccessGroups,
  isUserHasAccess,
} from "../../utils/utils";
import {
  DISABLE_SHARE_EMAIL_MODAL,
  FINISH_UPLOADING,
} from "../../store/types/Types";
import { ReactComponent as ReturncatalogSvg } from "../../imgs/catalog.svg";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ModalSuccess from "../ModalSuccess/ModalSuccess";
import countries from "../../data/aratzot.json";
import {
  isUploadingSelector,
  ShareEmailModalEnabledSelector,
  ShareEmailModalOpenSelector,
  uploadIsErrorSelector,
  uploadProgressSelector,
  userGroupsSelector,
  userMailSelector,
} from "../../store/selectors/selectors";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "@material-ui/core/Switch";
import ShareEmailModal from "../ShareEmailsModal/ShareEmailModal";
import * as Types from "../../store/types/Types";
import _ from "lodash";
import dataService from "../../services/dataService";
import RecaptchaLazy from "../RecaptchaLazy/RecaptchaLazy";
import FileTypesPopup from "../FileTypesPopup/FileTypesPopup";

//TODO move to utils
export function eventMsg(e: any) {
  let confirmationMessage = "o/";
  (e || window.event).returnValue = confirmationMessage;
  return confirmationMessage;
}

const defaultNewUpload = {
  dataset: "",
  description: "",
  country: "",
  organization: "",
  sourceName: "",
  sourceUrl: "",
  lastUpdated: null,
  sourceUpdateFrequency: "",
  emails: {},
  groups: {},
  files: [],
};

const countriesList = () => {
  let defaultValue: any = [];
  return defaultValue.concat(
    countries.map((country: any) => {
      return { value: country.country, label: country.country };
    })
  );
};

const useStyles = makeStyles(
  {
    outlined: {
      // ZIndex:"0px",
    },
  },
  { name: "MuiInputLabel" }
);

let Upload: React.FC;
Upload = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [newUpload, setNewUpload] = useState<any>(defaultNewUpload);
  const [filesLength, setFilesLength] = useState(0);
  const [currentDataset, setCurrentDataset] = useState("Public");
  const [isUserAllowed, setIsUserAllowed] = useState(false);
  const [privateAcess, setPrivateAcess] = useState(false);
  const [shareGroupsSwitch, setShareGroupsSwitch] = useState(false);
  const [allowedGroups, setAllowedGroups] = useState<any>(null);
  const [selectGroupsValue, setSelectGroupsValue] = useState<any>(null);
  const [recaptchaPass, setRecaptchaPass] = useState<any>(null);
  const [country, setCountry] = useState<any>({
    value: null,
    label: "Country*",
  });
  const userGroups = useSelector(userGroupsSelector);
  const uploadProgress = useSelector(uploadProgressSelector);
  const isError = useSelector(uploadIsErrorSelector);
  const isUploading = useSelector(isUploadingSelector);
  const shareWithEmailsOpen = useSelector(ShareEmailModalOpenSelector);
  const shareWithEmailsEnabled = useSelector(ShareEmailModalEnabledSelector);
  const userMail = useSelector(userMailSelector);
  const reCAPTCHARef = useRef<any>(null);

  useLayoutEffect(() => {
    if (userGroups) {
      if (isUserHasAccess(userGroups, "public", "write")) {
        setIsUserAllowed(true);
      }
      if (isUserHasAccess(userGroups, "private", "write")) {
        setPrivateAcess(true);
      }
    }
  }, [userGroups]);

  useLayoutEffect(() => {
    if (userGroups) {
      setAllowedGroups(generateUserAccessGroups("write", userGroups));
    }
  }, [userGroups]);

  useEffect(() => {
    console.log(newUpload)
  }, [newUpload.files])
  //reset on refresh render
  useEffect(() => {
    handleReset();
  }, []);

  //adding usermail to form
  useEffect(() => {
    setNewUpload({...newUpload, emails: {[userMail]: ["read", "write"]}})
  }, [userMail]);

  const handleEmailAddAccess = (emails: Array<string>, access: string) => {
    console.log(emails, "emails list", access);
    emails.forEach((email: string) => {
      switch (access) {
        case "delete":
          let newObjState = Object.assign({}, newUpload);
          delete newObjState["emails"][email];
          setNewUpload(newObjState);
          break;
        case "write":
          console.log(email, access);
          setNewUpload((prevState: any) => ({
            ...prevState,
            emails: { ...prevState["emails"], [email]: ["read", "write"] },
          }));
          break;
        case "read":
          setNewUpload((prevState: any) => ({
            ...prevState,
            emails: { ...prevState["emails"], [email]: ["read"] },
          }));
          break;
      }
    });
  };

  const handleChange = (e: any) => {
    setNewUpload({
      ...newUpload,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let isPublic: boolean = false;
    window.addEventListener("beforeunload", eventMsg);
    let modifiedNewUpload = { ...newUpload };
    //if no last updated replace with empty string - because of json stringify
    if (!modifiedNewUpload.lastUpdated) {
      modifiedNewUpload.lastUpdated = "";
    }
    if (_.isEmpty(modifiedNewUpload.groups)) {
      isPublic = true;
      modifiedNewUpload.groups = { public: ["read"] };
    }

    console.log(modifiedNewUpload, "modified upload", isPublic);
    //dispatch with private or public
    dispatch(uploadFilesPublic(modifiedNewUpload, isPublic));
  };

  const handleShareEmailSwitch = () => {
    if (!shareWithEmailsEnabled) {
      setNewUpload((prevState: any) => ({
        ...prevState,
        emails: {
          ...prevState.emails,
          [userMail]: ["read", "write"],
        },
      }));
      dispatch({ type: Types.ENABLE_SHARE_EMAIL_MODAL });
      dispatch({ type: Types.OPEN_SHARE_EMAIL_MODAL });
    } else if (shareWithEmailsEnabled && !shareWithEmailsOpen) {
      dispatch({ type: Types.DISABLE_SHARE_EMAIL_MODAL });
    }
  };

  const handleShareWithGroupsSwitch = () => {
    //clean up groups if user switching off
    if (shareGroupsSwitch) {
      setSelectGroupsValue(null);
      setNewUpload((prevState: any) => ({ ...prevState, groups: {} }));
    }
    setShareGroupsSwitch((prevState: boolean) => !prevState);
  };

  const handleSelectGroup = (e: any) => {
    setSelectGroupsValue(e);
    const groups: any = {};
    e.forEach((group: any) => {
      groups[group.value] = ["read"];
    });
    setNewUpload((prevState: any) => ({ ...prevState, groups: groups }));
  };

  const generateGroupsOptions = () => {
    let defaultValue: any = [];
    return defaultValue.concat(
      allowedGroups.map((group: any) => {
        return { value: group, label: capitalizeFirstLetter(group) };
      })
    );
  };

  const onChangeUploadFiles = (event: any) => {
    console.log(event.target.files.length)
    if (event.target.files.length > 0) {
      setFilesLength(event.target.files.length);
      const data = new FormData();
      for (let i = 0; i < event.target.files.length; i++) {
        data.append("file", event.target.files[i]);
      }
      setNewUpload({
        ...newUpload,
        [event.target.name]: data,
      });
    }
  };

  const handleOptionChange = (e: any) => {
    setCurrentDataset(e.target.value);
  };

  const handleCountrySelect = (e: any) => {
    setCountry(e);
    setNewUpload((prevState: any) => ({
      ...prevState,
      country: e.value,
    }));
  };

  const onDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length <= 5) {
      let event = { target: { files: acceptedFiles, name: "files" } };
      onChangeUploadFiles(event);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 15728640,
    onDropRejected<T extends File>(files: T[], event: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement> | DragEvent | Event): void {

    }
  });

  const handleReset = () => {
    setNewUpload(defaultNewUpload);
    setSelectGroupsValue(null);
    setShareGroupsSwitch(false);
    setCountry({
      value: null,
      label: "Country*",
    });
    setFilesLength(0);
    setRecaptchaPass(null);
    if (reCAPTCHARef.current) {
      reCAPTCHARef.current.reset();
    }
    dispatch({ type: DISABLE_SHARE_EMAIL_MODAL });
    dispatch({ type: FINISH_UPLOADING });
  };

  const handleRecaptcha = async (token: string) => {
    const res = await dataService.validRecaptcha(token);
    if (res) {
      setRecaptchaPass(true);
      console.log("recaptcha valid", res);
    } else {
      reCAPTCHARef.current.reset();
    }
  };

  if (!isUserAllowed)
    return (
      <div className="noaccess-container">
        <h1>You Dont Have Access To Upload Files</h1>
      </div>
    );
  return (
    <>
      <ModalSuccess reset={handleReset} />
      <ShareEmailModal
        handleAddEmails={handleEmailAddAccess}
        userList={newUpload.emails}
      />
      <div className="upload-container">
        <div className="upload-header-container">
          <Link className="upload_link" to="/datasets">
            <div className="returnCatalog-container">
              <ReturncatalogSvg className="returnCatalog-svg" />
              <p>Return to Catalog</p>
            </div>
          </Link>
          <h1 className="upload-title">Upload a Dataset</h1>
        </div>
        <form className="form-container" onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            id="dataset"
            label="Dataset Name"
            name="dataset"
            autoFocus
            value={newUpload.dataset}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            id="organization"
            label="Organization Name"
            name="organization"
            value={newUpload.organization}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            id="standard-multiline-static"
            label="Dataset Description"
            name="description"
            multiline
            rows={4}
            value={newUpload.description}
            onChange={handleChange}
          />
          <div className="upload-country-container">
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={countriesList()}
              isRtl={false}
              isSearchable={true}
              // defaultValue={{ value: "", label: "Country*" }}
              value={country}
              // onChange={(e: any) =>
              //   setNewUpload({ ...newUpload, country: e.value })
              // }
              onChange={(e: any) => handleCountrySelect(e)}
              maxMenuHeight={200}
            />
            {/*for required select form to be enabled*/}
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{ opacity: 0, height: 0 }}
              defaultValue={newUpload.country}
              required={true}
            />
          </div>
          <div className="upload-share-text-container">
            <p>Share with the world the details about the dataset</p>
          </div>
          <div className="upload-extra-fields-container">
            <div className="upload-extra-fields-box">
              <TextField
                variant="outlined"
                margin="normal"
                id="sourceName"
                label="Source Name"
                name="sourceName"
                value={newUpload.sourceName}
                onChange={handleChange}
              />
            </div>
            <div className="upload-extra-fields-box">
              <TextField
                variant="outlined"
                margin="normal"
                id="sourceUrl"
                label="Source URL"
                name="sourceUrl"
                value={newUpload.sourceUrl}
                onChange={handleChange}
              />
            </div>
            <div className="upload-extra-fields-box">
              <DatePicker
                selected={newUpload.lastUpdated}
                onChange={(e: any) =>
                  setNewUpload({ ...newUpload, lastUpdated: e })
                }
                placeholderText={"Last Updated"}
                className="dateinput"
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <div className="upload-extra-fields-box">
              <TextField
                variant="outlined"
                margin="normal"
                id="sourceUpdateFrequency"
                label="Update Frequency"
                name="sourceUpdateFrequency"
                value={newUpload.sourceUpdateFrequency}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="upload-switches-container">
            <p className="upload-share-privately-text">
              Share privately with people you know
            </p>
            {/*share emails switch*/}
            <div className="upload-sharewithemails-container">
              <Switch
                checked={shareWithEmailsEnabled}
                onChange={() => handleShareEmailSwitch()}
                name="shareWithEmails"
                inputProps={{ "aria-label": "primary checkbox" }}
                color={"primary"}
              />
              <div className="upload-sharewithemails-textContainer">
                <p className="upload-sharewithemails-maintext">
                  specific email addresses
                </p>
                {shareWithEmailsEnabled && !shareWithEmailsOpen && (
                  <p
                    onClick={() =>
                      dispatch({ type: Types.OPEN_SHARE_EMAIL_MODAL })
                    }
                    className="upload-sharewithemails-maintext upload-sharewithemails-edit"
                  >
                    Edit
                  </p>
                )}
              </div>
            </div>
            {/*share with groups*/}
            <div className="upload-sharewithgroups-container">
              <Switch
                checked={shareGroupsSwitch}
                onChange={() => handleShareWithGroupsSwitch()}
                name="shareWithGroups"
                inputProps={{ "aria-label": "primary checkbox" }}
                color={"primary"}
              />
              <p className="upload-sharewithemails-maintext">specific groups</p>
              <Select
                className={"upload-select-groups-container"}
                classNamePrefix="react-select"
                options={generateGroupsOptions()}
                isRtl={false}
                isSearchable={true}
                isDisabled={!shareGroupsSwitch}
                isMulti={true}
                value={selectGroupsValue}
                onChange={(e: any) => handleSelectGroup(e)}
                maxMenuHeight={200}
              />
            </div>
          </div>
          {/*starting of drag and drop upload files */}
          <div className="">
            {!isUploading ? (
              <div {...getRootProps()} className="uploadFileContainer">
                <input {...getInputProps()} />
                <UploadSvg title="" className="uploadSvg" />
                {filesLength === 0 && (
                    <>
                  <p className="uploadTxt">
                    Drag and drop here or click here to select files
                  </p>
                    <p className="upload-text-filesize">File size limit: 15MB</p>
                  </>
                )}
                {filesLength > 0 && (
                  <p className="uploadTxt">
                    {filesLength} Files have been selected for upload
                  </p>
                )}
                <input
                  tabIndex={-1}
                  autoComplete="off"
                  style={{ opacity: 0, height: 0 }}
                  defaultValue={newUpload.files}
                  required={true}
                />
              </div>
            ) : (
              <div className="uploadFileContainer">
                <div className="onUploadContainer">
                  <div>
                    <p className="onUpload-uploadingText">Uploading Files</p>
                  </div>
                  <div className="onUploadContainer onUploadContainer-progress">
                    <div className="onUploadContainer-progress-circular">
                      <CircularProgress
                        variant="determinate"
                        value={uploadProgress}
                      />
                    </div>
                    <div className="onUploadContainer-progress-textContainer">
                      <p className="onUpload-progress-percentageText">
                        {uploadProgress}%
                      </p>
                      <p className="onUpload-progress-secondaryText">
                        Completed
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          <FileTypesPopup/>
          </div>
          {!isUploading && (
            <div className="upload-recaptcha-container">
              <RecaptchaLazy
                refrence={reCAPTCHARef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY || ""}
                onChange={(e: any) => {
                  if (e.length) {
                    handleRecaptcha(e);
                  }
                }}
              />
              <input
                tabIndex={-1}
                autoComplete="off"
                style={{ opacity: 0, height: 0 }}
                defaultValue={recaptchaPass}
                required={false}
              />
            </div>
          )}
          <div className="reset-submit-btn-container">
            {isError.checkError && (
              <>
                <p className="upload-error">{isError.txt}</p>
                <button onClick={() => handleReset()} className="resetBtn">
                  Reset
                </button>
              </>
            )}

            {!isUploading && (
              <>
                <button onClick={() => handleReset()} className="resetBtn">
                  Reset
                </button>
                <button type={"submit"} className="submitBtn">
                  Submit
                </button>
              </>
            )}
            {/*{isUploadSuccess && (*/}
            {/*  <p className="successText">Upload Files Success</p>*/}
            {/*)}*/}
          </div>
        </form>
      </div>
    </>
  );
};

export default Upload;
