import { ThunkDispatch } from "redux-thunk";
import * as Types from "../types/Types";
import history from "../../history/history";
import authService from "../../services/authService";
import jwtDecode from "jwt-decode";

export const signUp = (userDetails: { email: String; password: String }) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.START_LOADING });
    try {
      const res = await authService.registerUser(userDetails);
      if (res.status === 200) {
        dispatch({ type: Types.REGISTER_SUCCESS, payload: res.data.data });
        console.log(res.data);
        dispatch({ type: Types.FINISH_LOADING });

        history.push("/datasets");
      }
    } catch (e) {
      dispatch({
        type: Types.GET_ERROR,
        payload: "Could Not Sign Up, Please Try Again",
      });
      dispatch({ type: Types.FINISH_LOADING });
    }
  };
};

export const signIn = (userDetails: { email: String; password: String }) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.START_LOADING });
    try {
      const res = await authService.signInUser(userDetails);
      if (res.status === 200) {
        dispatch({ type: Types.LOGIN_SUCCESS, payload: res.data.data });
        console.log(res.data);
        dispatch({ type: Types.FINISH_LOADING });

        history.push("/datasets");
      }
    } catch (e) {
      dispatch({
        type: Types.GET_ERROR,
        payload: "Could Not Login, Please Try Again",
      });
      dispatch({ type: Types.FINISH_LOADING });
    }
  };
};

export const logout = () => {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    try {
      console.log('logging out')
      authService.logoutUser().then(data=>{
        localStorage.removeItem("state");
        history.push("/");
        dispatch({ type: Types.LOGOUT_SUCCESS });
      })
    } catch (e) {
      dispatch({ type: Types.LOGIN_FAIL });
      history.push("/")
    }
  };
};

export const signInOauth = (token: any) => {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.START_LOADING });
    if (token) {
      const decodedToken: any = jwtDecode(token);
      if (decodedToken.groups && decodedToken.id) {
        // const data = {...decodedToken, token:token}
        dispatch({ type: Types.LOGIN_SUCCESS, payload: decodedToken });
        dispatch({ type: Types.FINISH_LOADING });
        // history.push("/datasets");
      } else {
        dispatch({
          type: Types.GET_ERROR,
          payload: "Could Not Login, Please Try Again",
        });
        dispatch({ type: Types.FINISH_LOADING });
       dispatch(logout())
      }
    } else {
      dispatch({
        type: Types.GET_ERROR,
        payload: "Could Not Login, Please Try Again",
      });
      dispatch({ type: Types.FINISH_LOADING });
      dispatch(logout())
    }
  };
};