import Axios from "axios";
import config from "../services/config.service";
import jwtDecode from "jwt-decode";
import { logout } from "../store/actions/authActions";
import store from "../store/store";

class AccessToken {
  private token: string;
  private tokenExp: number;
  constructor() {
    this.token = "";
    this.tokenExp = 0;
  }

  public getAccessToken = async () => {
    const current_time = Date.now().valueOf() / 1000;
    // check if token dont exist or if token exist but expired
    try {
      if (!this.token || this.tokenExp < current_time) {
        const res = await this.fetchNewAccessToken();
        if (res) {
          this.token = res;
          const { exp } = jwtDecode(this.token);
          this.tokenExp = exp;
          return this.token;
        } else {
          store.dispatch(logout());
        }
      } else {
        return this.token;
      }
    } catch (e) {
      store.dispatch(logout());
    }
  };

  public setAccessToken = (s: string) => {
    this.token = s;
  };

  public async fetchNewAccessToken() {
    try {
      const res = await Axios.post(
        `${config.server.url}/users/refresh_token`,
        null,
        { withCredentials: true }
      );
      console.log("fetch new access token");
      if (res.data.accessToken) {
        this.setAccessToken(res.data.accessToken);
        return res.data.accessToken;
      } else return false;
    } catch (e) {
      return false;
    }
  }
}

export default new AccessToken();
