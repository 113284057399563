import Axios from "axios";
import * as Types from "../store/types/Types";
import store from "../store/store";
import config from "../services/config.service";

const serverAddress = `${config.server.url}/storage`;
const privateServerAddress = `${serverAddress}/private`;

async function publicUploadFiles(upload: any, token: any, isPublic: boolean) {
  upload.files.set("dataset", upload.dataset);
  upload.files.set("organization", upload.organization);
  upload.files.set("description", upload.description);
  upload.files.set("country", upload.country);
  upload.files.set("sourceName", upload.sourceName);
  upload.files.set("sourceUrl", upload.sourceUrl);
  upload.files.set("lastUpdated", upload.lastUpdated);
  upload.files.set("sourceUpdateFrequency", upload.sourceUpdateFrequency);
  upload.files.set("emails", JSON.stringify(upload.emails));
  upload.files.set("groups", JSON.stringify(upload.groups));
  let path;
  isPublic ? (path = "public") : (path = "private");
  try {
    const res = await Axios.post(
      `${serverAddress}/upload/${path}`,
      upload.files,
      {
        onUploadProgress: (progressEvent) => {
          store.dispatch({
            type: Types.SET_UPLOAD_PROGRESS,
            payload: Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            ),
          });
        },
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": token,
        },
      }
    );
    return { status: res.status, data: res.data };
  } catch (err) {
    throw err;
  }
}

async function getPublicData(token: any) {
  const config: any = {
    headers: {
      "Content-type": "application/json",
    },
  };
  if (token) {
    config.headers["x-auth-token"] = token;
  }
  try {
    const res = await Axios.get(`${serverAddress}/list`, config);
    return { status: res.status, data: res.data };
  } catch (e) {
    throw e;
  }
}

function downloadFileService(
  token: any,
  filePath: string,
  fileName: string,
  prv: string
) {
  const config: any = {
    headers: {
      "Content-type": "application/json",
    },
  };
  if (token) {
    config.headers["x-auth-token"] = token;
  }
  Axios({
    url: `${serverAddress}/download?url=${filePath}&p=${prv}`,
    method: "GET",
    responseType: "blob", // important,
    headers: {
      "x-auth-token": token,
    },
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => err);
  return "Success";
}

async function getDatasetEmailAccess(token: any, dataset: string) {
  const config: any = {
    headers: {
      "Content-type": "application/json",
    },
  };
  if (token) {
    config.headers["x-auth-token"] = token;
  }
  try {
    const res = await Axios.get(
      `${serverAddress}/dataset/email?dataset=${dataset}`,
      config
    );
    return { status: res.status, data: res.data };
  } catch (e) {
    return { status: 400, msg: e };
  }
}

async function updateDatasetEmailAccess(
  token: any,
  emails: any,
  datasetPath: string,
  storage: string
) {
  const config: any = {
    headers: {
      "Content-type": "application/json",
    },
  };
  if (token) {
    config.headers["x-auth-token"] = token;
  }
  try {
    const res = await Axios.put(
      `${serverAddress}/dataset/updatemail`,
      { emails, datasetPath, storage },
      config
    );
    return { status: res.status, data: res.data };
  } catch (e) {
    return { status: 400, msg: e };
  }
}

// private data service function - currentl unsued, not in comment to not break code.
async function getPrivateData(token: any) {
  const config: any = {
    headers: {
      "Content-type": "application/json",
    },
  };
  if (token) {
    config.headers["x-auth-token"] = token;
  }
  try {
    const res = await Axios.get(`${privateServerAddress}/list`, config);
    return { status: res.status, data: res.data };
  } catch (e) {
    console.log("error from private data service");
    return { status: 400 };
  }
}

function privateDownloadFile(token: any, file: string) {
  const config: any = {
    headers: {
      "Content-type": "application/json",
    },
  };
  if (token) {
    config.headers["x-auth-token"] = token;
  }
  Axios({
    url: `${privateServerAddress}/download?url=${file}`,
    method: "GET",
    responseType: "blob", // important
    headers: {
      "x-auth-token": token,
    },
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => err);
  return "Success";
}

async function privateUploadFiles(upload: any, token: any) {
  upload.files.set("dataset", upload.dataset);
  upload.files.set("organization", upload.organization);
  upload.files.set("description", upload.description);
  try {
    const res = await Axios.post(
      `${privateServerAddress}/upload`,
      upload.files,
      {
        onUploadProgress: (progressEvent) => {
          store.dispatch({
            type: Types.SET_UPLOAD_PROGRESS,
            payload: Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            ),
          });
        },
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": token,
        },
      }
    );
    return { status: res.status, data: res.data };
  } catch (err) {
    console.log(err);
    return { status: 400 };
  }
}

async function validRecaptcha(token: string) {
  try {
    const res = await Axios.post(`${config.server.url}/recaptcha`, { token });
    return res.data.success;
  } catch (e) {
    return false;
  }
}

export default {
  uploadFiles: publicUploadFiles,
  getPublicData,
  downloadFile: downloadFileService,
  getPrivateData,
  privateDownloadFile,
  privateUploadFiles,
  getDatasetEmailAccess,
  updateDatasetEmailAccess,
  validRecaptcha,
};
