import * as Types from "../types/Types";

interface IDataState {
  publicData: { view: any; data: any; prev: any };
  privateData: { view: any; data: any; prev: any };
  error: { isError: any; txt: string };
  path: String | null;
  editEmailAccess: {
    isOn: Boolean;
    emails: any;
    datasetPath: string;
    uploadStatus: string;
    isPrivate: boolean | null;
  };
}

export const dataInitState = {
  publicData: { data: "", view: "", prev: null },
  privateData: { data: "", view: "", prev: null },
  error: { isError: false, txt: "" },
  path: "",
  editEmailAccess: {
    isOn: false,
    emails: "",
    datasetPath: "",
    uploadStatus: "",
    isPrivate: null,
  },
};

const dataReducer = (
  state: IDataState = dataInitState,
  action: { type: String; payload?: any }
) => {
  switch (action.type) {
    case Types.GET_PUBLIC_DATA:
      return {
        ...state,
        publicData: {
          view: action.payload,
          data: action.payload,
          prev: action.payload,
        },
        error: { isError: false, txt: "" },
      };
    case Types.GET_PRIVATE_DATA:
      return {
        ...state,
        privateData: {
          view: action.payload,
          data: action.payload,
          prev: action.payload,
        },
        error: { isError: false, txt: "" },
      };
    case Types.GET_PUBLIC_DATA_FAILED:
      return {
        ...dataInitState,
        error: { isError: true, txt: action.payload },
      };
    case Types.GET_PRIVATE_DATA_FAILED:
      return {
        ...dataInitState,
        // privateData: null,
        error: { isError: true, txt: action.payload },
      };
    case Types.SET_CURRENT_VIEW_PUBLIC:
      return {
        ...state,
        publicData: {
          ...state.publicData,
          prev: state.publicData.view,
          view: action.payload,
        },
      };
    case Types.SET_CURRENT_VIEW_PRIVATE:
      return {
        ...state,
        privateData: {
          ...state.privateData,
          prev: state.privateData.view,
          view: action.payload,
        },
      };
    case Types.SET_CURRENT_PATH:
      return {
        ...state,
        path: `${state.path}${action.payload}`,
      };
    case Types.SET_DEFAULT_VIEW_PUBLIC:
      return {
        ...state,
        publicData: {
          ...state.publicData,
          prev: null,
          view: state.publicData.data,
        },
        path: "",
      };
    case Types.SET_DEFAULT_VIEW_PRIVATE:
      return {
        ...state,
        privateData: {
          ...state.privateData,
          prev: null,
          view: state.privateData.data,
        },
        path: "",
      };
    case Types.SET_EDIT_EMAIL_ACCESS:
      return {
        ...state,
        editEmailAccess: {
          ...state.editEmailAccess,
          isOn: false,
          data: action.payload.emails,
          datasetPath: action.payload.dataset,
          isPrivate: action.payload.isPrivate,
        },
      };
    case Types.ENABLE_SET_EDIT_EMAIL_ACCESS:
      return {
        ...state,
        editEmailAccess: { ...state.editEmailAccess, isOn: true },
      };

    case Types.CLEAR_EDIT_EMAIL_ACCESS:
      return {
        ...state,
        editEmailAccess: {
          ...state.editEmailAccess,
          isOn: false,
          data: null,
          datasetPath: null,
          uploadStatus: "",
          isPrivate: null,
        },
      };

    case Types.CHANGE_EDIT_EMAIL_STATUS:
      return {
        ...state,
        editEmailAccess: {
          ...state.editEmailAccess,
          uploadStatus: action.payload,
        },
      };
    default:
      return state;
  }
};

export default dataReducer;
