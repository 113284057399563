import React from "react";
import history from "../../history/history";
import "./MobileLandingPage.css";
import "../../imgs/sadphone.svg"
import {ReactComponent as PhoneSvg} from "../../imgs/sadphone.svg";

const MobileLandingPage = () => {
    const handleClick = () => {
        history.push("/");
    };

    return (
        <div className="mobile-container">
            <div className="mobile-title-container">
                <h2 className="mobile-title">Corona Data Source</h2>
            </div>
            <div className="mobile-svg-container">
                <PhoneSvg className="mobile-svg"/>
            </div>
            <div className="mobile-sub-title-container">
                <h2 className="mobile-sub-title">
                    The site is currently unavailable for mobile devices
                </h2>
                <p className="mobile-sub-title-second" onClick={() => handleClick()}>
                    Back to Home Page
                </p>
            </div>
        </div>
    );
};

export default MobileLandingPage;