import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import authReducer from "./reducers/authReducer";
import uploadReduer from "./reducers/uploadReducer";
import dataReducer from "./reducers/dataReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    upload:uploadReduer,
    data:dataReducer
});

const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

const saveState = (state:any) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch {
        // ignore write errors
    }
};
const presistedState = loadState();


let middleware:any = [thunk]
console.log(process.env.REACT_APP_ENV)
if (process.env.REACT_APP_ENV === "dev"){
   middleware = [...middleware,logger]
}

const store = createStore(rootReducer, presistedState, composeWithDevTools(applyMiddleware(...middleware)));

store.subscribe(() => {
    saveState({
        data: store.getState().data,
        auth: store.getState().auth,
        upload: store.getState().upload
    });
});









// const store = createStore(
//     rootReducer,
//     composeWithDevTools(applyMiddleware(thunk, logger))
// );

export default store;