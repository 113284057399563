import { ThunkDispatch } from "redux-thunk";
import * as Types from "../types/Types";
import { OPEN_SUCCESS_MODAL } from "../types/Types";
import dataService from "../../services/dataService";
import { eventMsg } from "../../components/Upload/Upload";
import { logout } from "./authActions";
import AccessToken from "../../services/accessTokenService";

export const uploadFilesPublic = (data: any, isPublic: boolean) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>, getState: any) => {
    const accessToken = await AccessToken.getAccessToken();
    try {
      dispatch({ type: Types.START_UPLOADING });
      const uploadData = await dataService.uploadFiles(
        data,
        accessToken,
        isPublic
      );
      if (uploadData.status === 200) {
        dispatch({ type: Types.UPLOAD_SUCCESS });
        // dispatch({ type: Types.FINISH_UPLOADING });
        window.removeEventListener("beforeunload", eventMsg);
        dispatch({ type: OPEN_SUCCESS_MODAL });
      } else {
        dispatch({
          type: Types.UPLOAD_FAILED,
          payload: "Could Not Upload Files Please Try Again",
        });
        window.removeEventListener("beforeunload", eventMsg);
      }
    } catch (err) {
      {
        dispatch({
          type: Types.UPLOAD_FAILED,
          payload: "Could Not Upload Files Please Try Again",
        });
      }
      throw err;
    }
  };
};

export const getPublicDataAction = () => {
  return async (dispatch: ThunkDispatch<{}, {}, any>, getState: any) => {
    const accessToken = await AccessToken.getAccessToken();
    try {
      dispatch({ type: Types.START_LOADING });
      const publicData = await dataService.getPublicData(accessToken);
      if (publicData.status === 200) {
        dispatch({ type: Types.GET_PUBLIC_DATA, payload: publicData.data });
        dispatch({ type: Types.FINISH_LOADING });
      } else {
        dispatch({
          type: Types.GET_PUBLIC_DATA_FAILED,
          payload: "Could Not Get Public Data",
        });
        dispatch({ type: Types.FINISH_LOADING });
        dispatch(logout())
      }
    } catch (err) {
      {
        dispatch({
          type: Types.GET_PUBLIC_DATA_FAILED,
          payload:
            "Could Not Get Public Data, Check Your Access Or Try Login Again",
        });
        dispatch({ type: Types.FINISH_LOADING });
        dispatch(logout())
      }
      throw err;
    }
  };
};

export const downloadPublicFileAction = (
  filepath: string,
  fileName: string,
  prv: string
) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>, getState: any) => {
    // const token = getState().auth.token;
    const accessToken = await AccessToken.getAccessToken();
    try {
      dispatch({ type: Types.START_LOADING });
      const publicData = dataService.downloadFile(
        accessToken,
        filepath,
        fileName,
        prv
      );
      dispatch({ type: Types.FINISH_LOADING });
      // TODO add if download sucess dispatch downlaod sucess message if not downlaod failed
    } catch (err) {
      // {dispatch({type:Types.GET_DATA_FAILED, payload:"Could Not Get Public Data, Check Your Access Or Try Again"})}
      throw err;
    }
  };
};

export const setPublicFolderView = (folder: any, path: string) => {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.SET_CURRENT_VIEW_PUBLIC, payload: folder });
    dispatch({ type: Types.SET_CURRENT_PATH, payload: path });
  };
};

export const setDefaultView = (view: string) => {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    if (view === "Public") {
      dispatch({ type: Types.SET_DEFAULT_VIEW_PUBLIC });
    } else dispatch({ type: Types.SET_DEFAULT_VIEW_PRIVATE });
  };
};

// export const resetViewPath = () => {
//   return (dispatch: ThunkDispatch<{}, {}, any>) => {};
// };

export const getDatasetEmailAccessAction = (
  dataset: string,
  isPrivate: boolean
) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>, getState: any) => {
    const accessToken = await AccessToken.getAccessToken();
    try {
      dispatch({ type: Types.START_LOADING });
      const emailAccess = await dataService.getDatasetEmailAccess(
        accessToken,
        dataset
      );
      if (emailAccess.status === 200) {
        console.log(emailAccess.data, "email access data from action");
        const payload = {
          emails: emailAccess.data,
          dataset: dataset,
          isPrivate,
        };
        dispatch({
          type: Types.SET_EDIT_EMAIL_ACCESS,
          payload: payload,
        });
        dispatch({ type: Types.ENABLE_SET_EDIT_EMAIL_ACCESS });
        dispatch({ type: Types.FINISH_LOADING });
      } else {
        dispatch({ type: Types.FINISH_LOADING });
      }
    } catch (err) {
      {
        dispatch({ type: Types.FINISH_LOADING });
      }
      throw err;
    }
  };
};

export const updateDatasetEmailAccessAction = (
  emails: any,
  datasetPath: string,
  storage: string
) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>, getState: any) => {
    const accessToken = await AccessToken.getAccessToken();
    try {
      dispatch({ type: Types.START_LOADING });
      const emailAccess = await dataService.updateDatasetEmailAccess(
        accessToken,
        emails,
        datasetPath,
        storage
      );
      if (emailAccess.status === 200) {
        dispatch({ type: Types.FINISH_LOADING });
        dispatch({ type: Types.CHANGE_EDIT_EMAIL_STATUS, payload: "success" });
      } else {
        dispatch({
          type: Types.CHANGE_EDIT_EMAIL_STATUS,
          payload: "failed",
        });
        dispatch({ type: Types.FINISH_LOADING });
      }
    } catch (err) {
      {
        dispatch({
          type: Types.CHANGE_EDIT_EMAIL_STATUS,
          payload: "failed",
        });
        dispatch({ type: Types.FINISH_LOADING });
      }
      throw err;
    }
  };
};

// Private Actions currently not used

export const getPrivateDataAction = () => {
  return async (dispatch: ThunkDispatch<{}, {}, any>, getState: any) => {
    // const token = getState().auth.token;
    const accessToken = await AccessToken.getAccessToken();
    try {
      dispatch({ type: Types.START_LOADING });
      const privateData = await dataService.getPrivateData(accessToken);
      console.log(privateData);
      if (privateData.status === 200) {
        dispatch({ type: Types.GET_PRIVATE_DATA, payload: privateData.data });
        dispatch({ type: Types.FINISH_LOADING });
      } else {
        dispatch({
          type: Types.GET_PRIVATE_DATA_FAILED,
          payload: "Could Not Get Public Data",
        });
        dispatch({ type: Types.FINISH_LOADING });
      }
    } catch (err) {
      {
        dispatch({
          type: Types.GET_PRIVATE_DATA_FAILED,
          payload: "Could Not Get Public Data, Check Your Access Or Try Again",
        });
        dispatch({ type: Types.FINISH_LOADING });
      }
      throw err;
    }
  };
};

export const downloadPrivateFileAction = (file: string) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>, getState: any) => {
    const accessToken = await AccessToken.getAccessToken();
    try {
      dispatch({ type: Types.START_LOADING });
      const publicData = dataService.privateDownloadFile(accessToken, file);
      console.log(publicData);
    } catch (err) {
      // {dispatch({type:Types.GET_DATA_FAILED, payload:"Could Not Get Public Data, Check Your Access Or Try Again"})}
      throw err;
    }
  };
};

export const setPrivateFolderView = (folder: any, path: string) => {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.SET_CURRENT_VIEW_PRIVATE, payload: folder });
    dispatch({ type: Types.SET_CURRENT_PATH, payload: path });
  };
};


