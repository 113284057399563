// @ts-ignore
let config:any = {}
let env = process.env.REACT_APP_ENV
let isDev = false
let server:any = {url:process.env.REACT_APP_SERVER_URL}

// try {
//     if (env) {
//         env = env.trim();
//         console.log(env, 'env')
//     }
//     // config = require(`../config/config.${env}.json`)
//     if (env === "dev"){
//         isDev = true
//         config = require('../config/config')
//
//     }
// } catch (e) {
//     console.warn(`Unable to load configuration file: config.${env}.json\``, e);
// }
//
// if (isDev){
//     server = config.default.server
// } else server = process.env.ENV_URL
export default {
    server
}
