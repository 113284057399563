import React from "react";
import "./Home.css";
import defenceLogo from "../../imgs/defence.svg";
import healthLogo from "../../imgs/health.png";
import israelLogo from "../../imgs/israel.svg";
import telHashomerLogo from "../../imgs/telHashomer.png";
import tzahalLogo from "../../imgs/tzahal.png";
import weizmannLogo from "../../imgs/weizmann.png";
import mafatNewLogo from "../../imgs/mafat-logo-new.png";
import centerlogic from "../../imgs/centerlogic.png";
import { Link } from "react-router-dom";

const Home: React.FC = () => {
  return (
    <div>
      <section className="navbar-section">
        <div className="navbar-container">
          <div className="navbar-inner-container">
            <div className="nav-logo">
              <h1 className="nav-logo-title">
                Corona National <br />
                Data Source
              </h1>
            </div>
            <div className="nav-datasets-container">
              <a href="https://blog.mafatchallenge.com/" className="nav-datasets-title">
                BLOG
              </a>
              <Link to="/login" className="nav-datasets-title">
                DATASETS
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="main-section">
        <div className="main-section-div">
          <h1 className="main-section__title">
            The Israeli National
            <br /> Coronavirus Data Source
          </h1>
          <p className="main-section__subtext">
            This project gathers together Coronavirus (COVID-19) high-quality
            datasets and unique datasets.
            <br /> This catalog is the largest public and open Coronavirus data
            repository in Israel.
            <br /> The datasets are publicly open for your use.
          </p>
          <p className="main-section__subtext">Now we need your help!</p>
          <p className="main-section__subtext">
            We invite you to upload your datasets, investigate the shared
            collections and share your insights and ideas.
          </p>
          <p className="main-section__subtext sub-text_bold">
            Notice: please don't upload any dataset containing private
            information.
          </p>
          <p className="main-section__subtext">
            Please don’t hesitate to contact us with any question or help you
            need.
          </p>
          <p className="main-section__subtext">
            Help the world to understand better how to fight Coronavirus.
          </p>
        </div>
      </section>
      <section className="contributors-section">
        <h1 className="contributors-title">Contributors</h1>
        <div className="contributors-logos">
          <img
            src={centerlogic}
            alt="center-logo"
            className="contributors-logos__logo"
          />
          <img
            src={healthLogo}
            alt="health-logo"
            className="contributors-logos__logo"
          />
          <img
            src={mafatNewLogo}
            alt="mafat-logo"
            className="contributors-logos__logo"
          />
          <img
            src={israelLogo}
            alt="israel-logo"
            className="contributors-logos__logo"
          />
          <img
            src={defenceLogo}
            alt="defence-logo"
            className="contributors-logos__logo"
          />
          <img
            src={tzahalLogo}
            alt="tzahal-logo"
            className="contributors-logos__logo"
          />

          <img
            src={telHashomerLogo}
            alt="telHashomer-logo"
            className="contributors-logos__logo"
          />

          <img
            src={weizmannLogo}
            alt="weizmann-logo"
            className="contributors-logos__logo"
          />
        </div>
      </section>
      <section className="datasets-section" id="datasets-section">
        <div className="datasets-button-container">
          <Link to="/login">
            <button className="datasets-button__button">
              Link To Datasets
            </button>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Home;
