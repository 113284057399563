import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadPublicFileAction,
  getDatasetEmailAccessAction,
  getPublicDataAction,
  setDefaultView,
  setPublicFolderView,
} from "../../store/actions/dataActions";
import DescriptionIcon from "@material-ui/icons/Description";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FolderIcon from "@material-ui/icons/Folder";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import _ from "lodash";
import {
  datasetEmailEditSelector,
  isLoadingSelector,
  pathSelector,
  publicDataSelector,
  userGroupsSelector,
} from "../../store/selectors/selectors";
import { ReactComponent as IconSharingSvg } from "../../imgs/icon-sharing.svg";
import ShareEmailModalDataset from "../ShareEmailModalDataset/ShareEmailModalDataset";
import FadeIn from "react-fade-in";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: "60px",
    },
    file: {
      borderBottom: "1px solid gainsboro",
    },
    folderText: {
      borderBottom: "1px solid gainsboro",
    },
    dir: {
      border: "1px solid grey",
      display: "flex",
      fontSize: "1.1rem",
      marginTop: "5px",
    },
    buttons: {
      display: "flex",
      width: "50px",
      justifyConter: "center",
      alignItems: "center",
      paddingBottom: "10px",
    },
    buttonsText: {
      color: "#7d7d7d",
      letterSpacing: "0.14px",
    },
    upButton: {
      marginLeft: "20px",
      cursor: "pointer",
    },
    ul: {
      listStyle: "none",
      textAlign: "left",
      // padding: "30px"
    },
    downloadContainer: {
      display: "flex",
      cursor: "pointer",
      marginLeft: "auto",
      "&:hover": {
        color: "#3699fc",
      },
    },
    downloadText: {
      marginLeft: "10px",
      fontWeight: "bold",
    },
    editEmailsIcon: {
      marginLeft: "25px",
      height: "20px",
      cursor: "pointer",
    },
    text: {
      flex: "none",
      opacity: "0.9",
      cursor: "pointer",
      fontSize: "1.1rem",
      "&:hover": {
        color: "#3699fc",
      },
    },
  })
);

const Publicdata: React.FC = () => {
  const dispatch = useDispatch();
  const publicData = useSelector(publicDataSelector);
  const userGroups = useSelector(userGroupsSelector);
  const path = useSelector(pathSelector);
  const classes = useStyles();
  const emailEditModal = useSelector(datasetEmailEditSelector);
  const isLoading = useSelector(isLoadingSelector);

  useLayoutEffect(() => {
    dispatch(getPublicDataAction());
  }, [userGroups]);

  useEffect(() => {
    dispatch(setDefaultView("Public"));
  }, []);

  const handleFileDownload = (file: string, groups: any) => {
    const filePath = `${path}/${file}`;
    const fileGroups = groups;
    const isEmpty = _.isEmpty(groups);
    //  check if file is in private or public storage
    if (isEmpty || fileGroups["public"]) {
      dispatch(downloadPublicFileAction(filePath, file, "false"));
    } else {
      dispatch(downloadPublicFileAction(filePath, file, "true"));
    }
  };

  const handleFolderClick = (dir: any, name: string) => {
    const path = `/${name}`;
    dispatch(setPublicFolderView(dir, path));
  };

  const handleUpButton = () => {
    if (publicData) {
      dispatch(setDefaultView(publicData.view));
    }
  };

  const handleEditEmailsBtn = (dir: string, isPrivate: boolean) => {
    console.log(isPrivate);
    const datasetPath = `${path}/${dir}`;
    dispatch(getDatasetEmailAccessAction(datasetPath, isPrivate));
  };

  const generateFolders = (directories: any) => {
    if (directories) {
      const viewDir = Object.entries<any>(directories).map(([dir, value]) => (
        <div key={dir}>
            <FadeIn  >
          <ListItem className={classes.folderText}>
            <ListItemIcon>
              <FolderIcon />
            </ListItemIcon>
            <ListItemText
              className={classes.text}
              primary={dir}
              onClick={() => handleFolderClick(value, dir)}
            />
            {/* check if access to write in dataset */}
            {value.write && (
              <IconSharingSvg
                onClick={() => handleEditEmailsBtn(dir, value.p)}
                className={classes.editEmailsIcon}
              />
            )}
          </ListItem>
            </FadeIn>
        </div>
      ));
      return viewDir;
    }
  };

  // generating current view with folder and files
  const generateView = (data: any) => {
    if (data.files) {
      return (
        <ul className={classes.ul}>

          {generateFolders(data.directories)}
          {data.files.map((file: string) => (
              <FadeIn  >
            <ListItem key={file} className={classes.file}>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText className={classes.text} primary={file} />
              <div className={classes.downloadContainer}>
                <CloudDownloadIcon />
                <p
                  className={classes.downloadText}
                  onClick={() => handleFileDownload(file, data.groups)}
                >
                  Download
                </p>
              </div>
            </ListItem>
              </FadeIn>
          ))}
        </ul>
      );
    } else return <p>No Files To Show</p>;
  };
  return (
    <>
      {emailEditModal && <ShareEmailModalDataset />}
        {publicData && !isLoading && publicData.view && generateView(publicData.view)}
    </>
  );
};

export default Publicdata;
