import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadPrivateFileAction,
  getPrivateDataAction,
  setPrivateFolderView,
} from "../../store/actions/dataActions";
import DescriptionIcon from "@material-ui/icons/Description";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FolderIcon from "@material-ui/icons/Folder";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { isUserHasAccess } from "../../utils/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: "60px",
    },
    file: {
      borderBottom: "1px solid gainsboro",
    },
    folderText: {
      borderBottom: "1px solid gainsboro",
      cursor: "pointer",
    },
    dir: {
      border: "1px solid grey",
      display: "flex",
      fontSize: "1.1rem",
      marginTop: "5px",
    },
    buttons: {
      display: "flex",
      width: "50px",
      justifyConter: "center",
      alignItems: "center",
      paddingBottom: "10px",
    },
    buttonsText: {
      color: "#7d7d7d",
      letterSpacing: "0.14px",
    },
    upButton: {
      marginLeft: "20px",
      cursor: "pointer",
    },
    ul: {
      listStyle: "none",
      textAlign: "left",
      // padding: "30px"
    },
    datasetsTitle: {
      fontSize: "30px",
      fontWeight: "normal",
      textAlign: "left",
      marginBottom: "20px",
    },
    downloadContainer: {
      display: "flex",
      cursor: "pointer",
      "&:hover": {
        color: "#3699fc",
      },
    },
    downloadText: {
      marginLeft: "10px",
      fontWeight: "bold",
    },
    text: {
      "&:hover": {
        color: "#3699fc",
      },
    },
  })
);

const Privatedata: React.FC = () => {
  const dispatch = useDispatch();
  const privateData = useSelector((state: any) => state.data.privateData.data);
  const currentView = useSelector((state: any) => state.data.privateData.view);
  const path = useSelector((state: any) => state.data.path);
  const classes = useStyles();
  const userGroups = useSelector((state: any) => state.auth.groups);
  const [isUserAllowed, setIsUserAllowed] = useState(false);

  useLayoutEffect(() => {
    if (userGroups) {
      if (isUserHasAccess(userGroups, "private", "read")) {
        setIsUserAllowed(true);
      }
    }
  }, [userGroups]);

  useEffect(() => {
    if (isUserAllowed) {
      dispatch(getPrivateDataAction());
    }
  }, [isUserAllowed]);

  const handleFileDownload = (file: string) => {
    const filePath = `${path}${file}`;
    console.log(filePath);
    dispatch(downloadPrivateFileAction(file));
  };

  const handleFolderClick = (dir: any, name: string) => {
    const path = `/${name}`;
    dispatch(setPrivateFolderView(dir, path));
  };

  const generateFolders = (directories: any) => {
    const viewDir = Object.entries(directories).map(([dir, value]) => (
      <ListItem
        key={dir}
        className={classes.folderText}
        onClick={() => handleFolderClick(value, dir)}
      >
        <ListItemIcon>
          <FolderIcon />
        </ListItemIcon>
        <ListItemText className={classes.text} primary={dir} />
      </ListItem>
    ));
    return viewDir;
  };

  const generateView = (data: any) => {
    return (
      <ul className={classes.ul}>
        {generateFolders(data.directories)}
        {data.files.map((file: string) => (
          <ListItem key={file} className={classes.file}>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText className={classes.text} primary={file} />
            <div className={classes.downloadContainer}>
              <CloudDownloadIcon />
              <p
                className={classes.downloadText}
                onClick={() => handleFileDownload(file)}
              >
                Download
              </p>
            </div>
          </ListItem>
        ))}
      </ul>
    );
  };

  if (!isUserAllowed)
    return (
      <div className="noaccess-container">
        <h1>You Dont Have Access To See Private Files</h1>
      </div>
    );
  return <>{currentView && generateView(currentView)}</>;
};

export default Privatedata;
