import React from "react";
import Modal from "react-modal";
import {ReactComponent as SuccessSvg} from "../../imgs/upload_suc.svg";
import "./ModalSuccess.css";
import {ReactComponent as ReturncatalogSvg} from "../../imgs/catalog.svg";
import {useDispatch, useSelector} from "react-redux";
import history from "../../history/history";
import {uploadSuccessModalSelector} from "../../store/selectors/selectors";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "350px",
    height: "400px",
    opacity: "1",
    zIndex: 22,
    padding: "20px 0",
  },
};

const svgStyles = {height: "20px", marginRight: "5px"};

interface IModalSuccessProps {
  reset: any;
}
Modal.setAppElement("#root");
const ModalSuccess: React.FC<IModalSuccessProps> = (props: any) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(uploadSuccessModalSelector);

  const handleReset = () => {
    //  reset + close modal
    props.reset();
    dispatch({ type: "CLOSE_SUCCESS_MODAL" });
  };

  const handleReturnCatalog = () => {
    //   reset+closeModal + history push
    props.reset();
    dispatch({ type: "CLOSE_SUCCESS_MODAL" });
    history.push("/datasets");
  };

  return (
    <Modal isOpen={isOpen} style={customStyles} contentLabel="Success Modal">
      <div className="modal-success-container">
        <SuccessSvg />
        <div className="modal-success-maintext">
          <h1 className="modal-success-successText">SUCCESS!</h1>
          <p className="modal-success-subtext">Data uploaded successfully</p>
        </div>
        <div className="modal-success-manual-container">
          <AccessTimeIcon
              className="modal-modal-success-icon"
              style={svgStyles}
          />
          <p className="modal-success-manual-text">
            The dataset will be shown after manual verification
          </p>
        </div>
        <div
            className="returnCatalog-container modal-success-catalog"
            onClick={handleReturnCatalog}
        >
          <ReturncatalogSvg className="returnCatalog-svg"/>
          <p>Return to Catalog</p>
        </div>
        <div>
          <p className="modal-success-upload" onClick={handleReset}>
            Upload another dataset
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSuccess;
