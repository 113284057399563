// @ts-ignore
import React, {useEffect, useLayoutEffect, useState} from "react";
import "./App.css";
import {Route, Switch, Redirect, useLocation} from "react-router";
import Header from "./components/Header/Header";
import Upload from "./components/Upload/Upload";
import {useDispatch, useSelector} from "react-redux";
import Login from "./components/Login/Login";
import Home from "./components/Home/Home";
import Datasets from "./components/Datasets/Datasets";
import Oauth from "./components/Oauth/Oauth";
import {createMuiTheme, ThemeProvider} from "@material-ui/core";
import {isAuthSelector} from "./store/selectors/selectors";
import AccessToken from "./services/accessTokenService";
import isMobile from "ismobilejs";
import {signInOauth} from "./store/actions/authActions";
import MobileLandingPage from "./components/MobileLandingPage/MobileLandingPage";

const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiInputLabel: {
      // Name of the rule
      outlined: {
        // Some CSS
        zIndex: 0,
      },
    },
    MuiOutlinedInput: {
      input: {
        zIndex: -1,
      },
    },
  },
});

const PublicRoutes: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/login" component={Login} exact />
        <Route path="/oauth" component={Oauth} />
        <Redirect to="/" />
      </Switch>
    </>
  );
};

const Routes: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/datasets" component={Datasets} exact />
        <Route path="/login" component={Login} exact />
        <Route path="/upload" component={Upload} exact />
        <Route path="/oauth" component={Oauth} />
        <Redirect to="/" />
      </Switch>
    </>
  );
};

const App: React.FC = () => {
  const isAuthenticated = useSelector(isAuthSelector);
  const checkMobile = isMobile().phone;
  const location = useLocation();
  const dispatch = useDispatch();
  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {
    AccessToken.fetchNewAccessToken().then((data) => {
      dispatch(signInOauth(data));
    });
  }, []);
  //
  useLayoutEffect(() => {
    if (isAuthenticated && location.pathname === "/") {
      setShowHeader(false);
    }
    if (location.pathname !== "/") {
      setShowHeader(true);
    }
  }, [location, isAuthenticated]);
  //Check if user login ---
  //   if (isAuthenticated && location.pathname === "/login") {
  //     history.push("/datasets");
  //   }
  // }, [location,isAuthenticated]);

  if (!isAuthenticated) {
    return (
        <>
          {!checkMobile &&
          <div className="App">
            <PublicRoutes/>
          </div>
          }
          {checkMobile && location.pathname !== "/" && <MobileLandingPage/>}
          {checkMobile && location.pathname === "/" && <Route path="/" component={Home} exact/>}
        </>
    );
  } else
    return (
        <>
          {!checkMobile && (
              <ThemeProvider theme={theme}>
                <div className="App">
                  {showHeader && <Header/>}
                  <Routes/>
                </div>
              </ThemeProvider>
          )}
          {/*on mobile only show home page*/}
          {checkMobile && location.pathname === "/" && <Route path="/" component={Home} exact/>}
          {checkMobile && location.pathname !== "/" && <MobileLandingPage/>}
        </>
    );
};

export default App;
