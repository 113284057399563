export const START_LOADING = "START_LOADING";
export const FINISH_LOADING = "FINISH_LOADING";
export const FINISH_UPLOADING = "FINISH_UPLOADING";
export const START_UPLOADING = "START_UPLOADING";
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const GET_ERROR = "GET_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const UPLOAD_FAILED = "UPLOAD_FAILED";
export const GET_PUBLIC_DATA = "GET_PUBLIC_DATA";
export const GET_PUBLIC_DATA_FAILED = "GET_DATA_FAILED";
export const SET_CURRENT_VIEW_PUBLIC = "SET_CURRENT_VIEW_PUBLIC";
export const SET_CURRENT_PATH = "SET_CURRENT_PATH";
export const SET_DEFAULT_VIEW_PUBLIC = "SET_DEFAULT_VIEW_PUBLIC";
export const GET_PRIVATE_DATA = "GET_PRIVATE_DATA";
export const GET_PRIVATE_DATA_FAILED = "GET_PRIVATE_DATA_FAILED";
export const SET_CURRENT_VIEW_PRIVATE = "SET_CURRENT_VIEW_PRIVATE";
export const SET_DEFAULT_VIEW_PRIVATE = "SET_DEFAULT_VIEW_PRIVATE";
export const OPEN_SUCCESS_MODAL = "OPEN_SUCCESS_MODAL";
export const CLOSE_SUCCESS_MODAL = "CLOSE_SUCCESS_MODAL";
export const OPEN_SHARE_EMAIL_MODAL = "OPEN_SHARE_EMAIL_MODAL";
export const CLOSE_SHARE_EMAIL_MODAL = "CLOSE_SHARE_EMAIL_MODAL";
export const ENABLE_SHARE_EMAIL_MODAL = "ENABLE_SHARE_EMAIL_MODAL";
export const DISABLE_SHARE_EMAIL_MODAL = "DISABLE_SHARE_EMAIL_MODAL";
export const ENABLE_SET_EDIT_EMAIL_ACCESS = "ENABLE_SET_EDIT_EMAIL_ACCESS";
export const SET_EDIT_EMAIL_ACCESS = "SET_EDIT_EMAIL_ACCESS";
export const CHANGE_EDIT_EMAIL_STATUS = "CHANGE_EDIT_EMAIL_STATUS";
export const CLEAR_EDIT_EMAIL_ACCESS = "CLEAR_EDIT_EMAIL_ACCESS";
