import * as Types from "../types/Types"

interface IUploadState {
    uploadStatus: any,
    isLoading: any,
    isError: any,
    isUploading: any,
    uploadProgress: any,
    uploadDone:Boolean,
    successModal:Boolean,
    shareEmailModalOpen:Boolean
    shareEmailModalEnabled:Boolean
}

export const uploadInitState = {
    uploadStatus: null,
    isLoading: false,
    isError: {checkError: false, txt: ""},
    isUploading: false,
    uploadDone:false,
    uploadProgress: 0,
    successModal:false,
    shareEmailModalOpen:false,
    shareEmailModalEnabled:false
};

const uploadReduer = (
    state: IUploadState = uploadInitState,
    action: { type: String; payload?: any }
) => {
    switch (action.type) {
        case Types.START_UPLOADING:
        return {
            ...state,
            isLoading:true,
            isUploading:true,
            uploadStatus:"uploading"
        };
        case Types.FINISH_UPLOADING:
            return {
                ...state,
                isLoading: false,
                isUploading: false,
                isError: {checkError: false, txt: ""},
            };
        case Types.UPLOAD_SUCCESS:
            return{
                ...state,
                uploadStatus: "success"
            }

        case Types.UPLOAD_FAILED:
            return {
                ...state,
                uploadStatus: "failed",
                isError:{checkError:true,txt:action.payload}
            }
        case Types.SET_UPLOAD_PROGRESS:
            return {
                ...state,
                uploadProgress: action.payload
            }
        case Types.OPEN_SUCCESS_MODAL:
            return {
                ...state,
                successModal:true
            };
        case Types.CLOSE_SUCCESS_MODAL:
            return {
                ...state,
                successModal:false
            };
        case Types.OPEN_SHARE_EMAIL_MODAL:
            return {
                ...state,
                shareEmailModalOpen:true
            };
        case Types.CLOSE_SHARE_EMAIL_MODAL:
            return {
                ...state,
                shareEmailModalOpen:false
            };
        case Types.ENABLE_SHARE_EMAIL_MODAL:
            return {
                ...state,
                shareEmailModalEnabled:true
            };
        case Types.DISABLE_SHARE_EMAIL_MODAL:
            return {
                ...state,
                shareEmailModalEnabled:false
            };
        default:
            return state;
    }
};

export default uploadReduer;