import data from '../data/aratzot.json'
import _ from 'lodash'

export function isUserHasAccess(groups:any,group:string,access:string){
    return groups[group].some((e:any)=>e === access)
}

export function generateUserAccessGroups(access:string,groups:any){
    const allowedGroups = []
    for (const group in groups){
        if (groups[group].some((e:any)=>e === access)) {
            allowedGroups.push(group)
        }
    }
    return allowedGroups
}


export function capitalizeFirstLetter(string:String) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function isEmpty(obj:any) {
    let empty = true
    for (const x in obj) {
        if(x === "directories"){
            for(const dir in obj[x]){
                return  false
            }
        }
        if (x=== "files"){
            if (obj[x].length){
                       return false
            }
        }
    }
    return empty
}

export function filterDatasetsByGroups(validGroups:any,datasets:any){
    //using deep clone by lodash for deep cloning directories object
    const directories:any = _.cloneDeep(datasets)
    for (const organization in directories.directories){
        // @ts-ignore
        for (const dataset in directories.directories[organization].directories){
            let isMatch = false
            for (let group in validGroups){
                if(validGroups[group]=== "email" && Object.keys(directories.directories[organization].directories[dataset].groups).length === 0){
                 isMatch = true
                }
                if (directories.directories[organization].directories[dataset].groups[validGroups[group]]){
                    isMatch = true
                }
            }
            if (!isMatch) {
                //if no match delete dataset from object also check if parent folder empty delete it too.
                delete directories.directories[organization]["directories"][dataset]
                    if (isEmpty(directories.directories[organization])){
                                        delete directories.directories[organization]
                                    }
            }
        }
    }
    console.log(directories)
    return directories
}
