import React from "react";
import Popup from "reactjs-popup";
import "../FileTypesPopup/FileTypesPopup.css";

const Card = () => (
  <div className="filetypes-card">
    <div className="filetypes-header">Supported file types </div>
    <div className="filetypes-content">
      zip, wav, csv, docx, jpg, jpeg, png, rar, xlsx, pdf, mp3, xml, json, xls,
      avi, eps, txt, pkl, hdf5.
    </div>
  </div>
);

const FileTypesPopup = () => {
  return (
    <>
      <p className="upload-text-trouble">
        Having trouble uploading? make sure your
          <Popup trigger={<span className="filetypes-here"> file type is valid </span>} position="bottom center" on="hover">
          <Card />
        </Popup>
      </p>
    </>
  );
};


export  default FileTypesPopup