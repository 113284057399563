// @ts-ignore
import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions/authActions";
import BackupIcon from "@material-ui/icons/Backup";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { ReactComponent as LogoutSvg } from "../../imgs/logout.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // flexGrow: 1
      display: "flex",
      justifyContent: "space-between",
    },
    sideLinks: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      // flexGrow: 1,
      display: "flex",
      justifyContent: "center",
        alignItems: "center",
      textDecoration: "none",
      color: "white",
      margin: "10px",
      cursor: "pointer",
    },
    brand: {
      display: "flex",
      justifyContent: "center",
      textDecoration: "none",
      color: "white",
      margin: "10px",
    },
    icons: {
      marginRight: "10px",
      height: "20px",
      width: "20px",
    },
    link: {
      textDecoration: "none",

    },
  })
);

const Header: React.FC = () => {
  const classes = useStyles();
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.root}>
          <Typography variant="h6" className={classes.brand}>
            Corona Data Source
          </Typography>
          {isAuthenticated && (
            <div className={classes.sideLinks}>
              <Link to="/upload" className={classes.link}>
                <Typography variant="subtitle1" className={classes.title}>
                  <BackupIcon className={classes.icons} /> Upload
                </Typography>
              </Link>
              <Typography
                variant="subtitle1"
                className={classes.title}
                onClick={handleLogout}
              >
                <LogoutSvg className={classes.icons} /> Logout
              </Typography>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
