import React from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  ShareEmailModalEnabledSelector,
  ShareEmailModalOpenSelector,
  userMailSelector,
} from "../../store/selectors/selectors";
import "./ShareEmailModa.css";
import AddEmails, { accessOptions } from "../AddEmails/AddEmails";
import * as Types from "../../store/types/Types";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Select from "react-select";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    // minWidth: "600px",
    height: "60%",
    opacity: "1",
    zIndex: 22,
  },
};

interface IShareEmailModalProps {
  handleAddEmails: any;
  userList: any;
}
const createLabelAndValue = (array: any) => {
  let access = { value: "read", label: "View" };
  if (array.some((permission: string) => permission === "write")) {
    access = { value: "write", label: "Edit" };
  }
  return access;
};

const ShareEmailModal: React.FC<IShareEmailModalProps> = (props: any) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(ShareEmailModalOpenSelector);
  const isEnabled = useSelector(ShareEmailModalEnabledSelector);
  const userEmail = useSelector(userMailSelector);

  const handleDoneButton = () => {
    dispatch({ type: Types.CLOSE_SHARE_EMAIL_MODAL });
  };

  const handleChangeUserAccess = (email: string, newAccess: string) => {
    console.log(email, newAccess);
    props.handleAddEmails([email], newAccess);
  };

  const handleUserAccessDelete = (user: string) => {
    console.log(user);
    props.handleAddEmails([user], "delete");
  };

  const createUserList = () => {
    let userList: any = [];
    for (const user in props.userList) {
      if (user !== userEmail) {
        userList.push(
          <div className="shareEmail-accessList-rowContainer" key={user}>
            <div className="shareEmail-accessList-textIconWrapper">
              <AccountCircleIcon />
              <p className="shareEmail-accessList-mailtext">{user}</p>
            </div>
            <div className="shareEmail-accessList-selectWrapper">
              <Select
                className="shareemail-accesslist-select-react-select"
                classNamePrefix="react-select"
                options={accessOptions}
                isRtl={false}
                isSearchable={false}
                defaultValue={createLabelAndValue(props.userList[user])}
                onChange={(e: any) => handleChangeUserAccess(user, e.value)}
                maxMenuHeight={200}
              />
              <p
                className="shareEmail-accessList-delete-x"
                onClick={() => handleUserAccessDelete(user)}
              >
                x
              </p>
            </div>
          </div>
        );
      } else {
        userList.push(
          <div className="shareEmail-accessList-rowContainer" key={user}>
            <div className="shareEmail-accessList-textIconWrapper">
              <AccountCircleIcon />
              <p className="shareEmail-accessList-mailtext">{user}</p>
            </div>
          </div>
        );
      }
    }
    return userList;
  };

  return (
    <Modal isOpen={isOpen} style={customStyles} contentLabel="Success Modal">
      <div className="modal-shareEmail-container">
        <div className="shareEmail-header">
          <h1 className="shareEmail-header-text">Sharing Settings</h1>
        </div>
        <div className="shareEmail-addEmails-container">
          <AddEmails handleAddEmails={props.handleAddEmails} />
        </div>
        <div>
          <h3>Who has access:</h3>
          <div className="shareEmail-accessList-container">
            {createUserList()}
          </div>
        </div>

        <div className="shareEmail-doneBtn-container">
          <button
            className="add-emails-addBtn"
            onClick={() => handleDoneButton()}
          >
            Done
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ShareEmailModal;
