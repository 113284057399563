import React, {useEffect, useState} from "react";
import "../Signup/Signup.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {useDispatch, useSelector} from "react-redux";
// import { signUp } from "../../store/actions/authActions";
import {signIn} from "../../store/actions/authActions";
import {useLocation} from "react-router-dom";
import GoogleButton from "react-google-button";
import config from "../../services/config.service";
import {isAuthSelector} from "../../store/selectors/selectors";
import {RouteComponentProps} from "react-router";

const useStyles = makeStyles(theme => ({
    "@global": {
        body: {
            backgroundColor: theme.palette.common.white
        }
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        justifyContent:"center",
        alignItems: "center"
    },
    googleBtn:{
      width:"100%",
      margin:"20px"
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

interface ISignUpProps extends RouteComponentProps{
    handleUser: (userDetails: { username: String; password: String }) => void;
    error: Object;
}

const Login: React.FC<ISignUpProps> = ({history}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation()
    const error = useSelector((state: any) => state.auth.error);
    const isLoading = useSelector((state: any) => state.auth.isLoading);
    const isAuthenticated = useSelector(isAuthSelector);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");


    useEffect(()=>{
        if (isAuthenticated) {
            history.push('/datasets')
        }},[isAuthenticated]);

    const handleSubmit = (e: React.SyntheticEvent<EventTarget>) => {
        e.preventDefault();
        const userDetails = { email, password };
        // handleUser(userDetails);
        dispatch(signIn(userDetails));
    };

    const handleGoogle = ()=>{
        // @ts-ignore
        window.location.assign((`${config.server.url}/users/google` ||  process.env.ENV_URL))
    };


    // @ts-ignore
    return (
        <Container
            component="main"
            maxWidth="sm"
            onSubmit={handleSubmit}
            className="signup-container"
        >
            <h1>Welcome To The Israeli National<br/> Coronavirus - Database  </h1>
            <h3>Please Login</h3>
            <CssBaseline />
            <form className={classes.form} noValidate>
                <GoogleButton className={classes.googleBtn} onClick={()=>handleGoogle()}/>
            </form>
            <Box mt={8}></Box>
        </Container>
    );
};

export default Login;