import React, { Suspense, useEffect, useState } from "react";
import { ReCAPTCHAProps } from "react-google-recaptcha";
import CircularProgress from "@material-ui/core/CircularProgress";
//lazy loading recapcha
const ReCAPTCHA = React.lazy(() => import("react-google-recaptcha"));

interface RecaptchaLazyProps extends ReCAPTCHAProps {
  refrence: any;
}

const RecaptchaLazy: React.FC<RecaptchaLazyProps> = ({
  sitekey,
  onChange,
  refrence,
}) => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <ReCAPTCHA sitekey={sitekey} ref={refrence} onChange={onChange} />
    </Suspense>
  );
};

export default RecaptchaLazy;
