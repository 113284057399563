import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  datasetEmailEditSelector,
  isLoadingSelector,
  ShareEmailModalEnabledSelector,
  userMailSelector,
} from "../../store/selectors/selectors";
import "../ShareEmailsModal/ShareEmailModa.css";
import AddEmails, { accessOptions } from "../AddEmails/AddEmails";
import { CircularProgress } from "@material-ui/core";
import * as Types from "../../store/types/Types";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Select from "react-select";
import { updateDatasetEmailAccessAction } from "../../store/actions/dataActions";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    // minWidth: "600px",
    height: "60%",
    opacity: "1",
    zIndex: 22,
  },
};


const createLabelAndValue = (array: any) => {
  let access = { value: "read", label: "View" };
  if (array.some((permission: string) => permission === "write")) {
    access = { value: "write", label: "Edit" };
  }
  return access;
};

const ShareEmailModalDataset: React.FC = () => {
  const dispatch = useDispatch();
  const isPrivateStorage = useSelector(
      (state: any) => state.data.editEmailAccess.isPrivate
  );
  const isEnabled = useSelector(ShareEmailModalEnabledSelector);
  const emailEditModal = useSelector(datasetEmailEditSelector);
  const isLoading = useSelector(isLoadingSelector);
  const userEmail = useSelector(userMailSelector);
  const [emailsAccess, setEmailAccess] = useState<any>({});

  useEffect(() => {
    setEmailAccess(emailEditModal.data);
  }, [emailEditModal]);

  //clear after success update
  useEffect(() => {
    if (emailEditModal.uploadStatus === "success") {
      setTimeout(() => {
        handleCancelButton();
      }, 1500);
    }
  }, [emailEditModal.uploadStatus]);


  const handleCancelButton = () => {
    dispatch({ type: Types.CLEAR_EDIT_EMAIL_ACCESS });
  };

  const handleChangeUserAccess = (email: string, newAccess: string) => {
    handleEmailAddAccess([email], newAccess);
  };

  const handleUserAccessDelete = (user: string) => {
    handleEmailAddAccess([user], "delete");
  };

  const handleEmailAddAccess = (emails: Array<string>, access: string) => {
    emails.forEach((email: string) => {
      switch (access) {
        case "delete":
          let newObjState = Object.assign({}, emailsAccess);
          delete newObjState[email];
          setEmailAccess(newObjState);
          break;
        case "write":
          console.log(email, access);
          setEmailAccess((prevState: any) => ({
            ...prevState,
            [email]: ["read", "write"],
          }));
          break;
        case "read":
          setEmailAccess((prevState: any) => ({
            ...prevState,
            [email]: ["read"],
          }));
          break;
      }
    });
  };

  const handleUpdateBtn = () => {
    //    dispatch with path and with new object
    let storage = isPrivateStorage ? "private" : "public";
    dispatch(
        updateDatasetEmailAccessAction(
            emailsAccess,
            emailEditModal.datasetPath,
            storage
        )
    );
  };

  const createUserList = () => {
    let userList: any = [];
    for (const user in emailsAccess) {
      if (user !== userEmail) {
        userList.push(
          <div className="shareEmail-accessList-rowContainer" key={user}>
            <div className="shareEmail-accessList-textIconWrapper">
              <AccountCircleIcon />
              <p className="shareEmail-accessList-mailtext">{user}</p>
            </div>
            <div className="shareEmail-accessList-selectWrapper">
              <Select
                className="shareemail-accesslist-select-react-select"
                classNamePrefix="react-select"
                options={accessOptions}
                isRtl={false}
                isSearchable={false}
                defaultValue={createLabelAndValue(emailsAccess[user])}
                onChange={(e: any) => handleChangeUserAccess(user, e.value)}
                maxMenuHeight={200}
              />
              <p
                className="shareEmail-accessList-delete-x"
                onClick={() => handleUserAccessDelete(user)}
              >
                x
              </p>
            </div>
          </div>
        );
      } else {
        userList.push(
          <div className="shareEmail-accessList-rowContainer" key={user}>
            <div className="shareEmail-accessList-textIconWrapper">
              <AccountCircleIcon />
              <p className="shareEmail-accessList-mailtext">{user}</p>
            </div>
          </div>
        );
      }
    }
    return userList;
  };

  return (
    <Modal
      isOpen={emailEditModal.isOn}
      style={customStyles}
      contentLabel="Success Modal"
    >
      <div className="modal-shareEmail-container">
        <div className="shareEmail-header">
          <h1 className="shareEmail-header-text">Sharing Settings</h1>
        </div>
        <div className="shareEmail-addEmails-container">
          <AddEmails handleAddEmails={handleEmailAddAccess} />
        </div>
        <div>
          <h3>Who has access:</h3>
          <div className="shareEmail-accessList-container">
            {createUserList()}
          </div>
        </div>
        {emailEditModal.uploadStatus === "success" && (
          <p className="shareEmail-dataset-success-msg">Update Success</p>
        )}
        {emailEditModal.uploadStatus === "failed" && (
          <p className="shareEmail-dataset-failed-msg">
            Update Failed, please try again
          </p>
        )}
        <div className="shareEmail-dataset-button-container">
          <div className="shareEmail-updateCancel-container">
            <button
              className="add-emails-CancelBtn"
              onClick={() => handleCancelButton()}
            >
              Cancel
            </button>
          </div>
          <div className="shareEmail-updateCancel-container">
            <button
              className="add-emails-addBtn"
              onClick={() => handleUpdateBtn()}
            >
              {isLoading ? <CircularProgress /> : "Update"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShareEmailModalDataset;
