import {
  CLEAR_ERROR,
  FINISH_LOADING,
  GET_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  START_LOADING,
  USER_LOGGED_IN
} from "../types/Types";

const authInitState = {
  isLoggedIn: false,
  // token: null,
  isAuthenticated: false,
  email: null,
  groups:null,
  error: null,
  userId: null,
  isLoading:false
};

//TODO delete token comment

const authReducer = (state = authInitState, action: any) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case FINISH_LOADING:
      return {
        ...state,
        isLoading: false
      };
    case USER_LOGGED_IN:
      return {
        ...state,
        isAuthenticated: true,
        isLoggedIn: true,
        isLoading: false,
        email: action.payload.email,
        groups: action.payload.groups,
        // token: action.payload.token
      };
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        isAuthenticated: true,
        isLoggedIn: true,
        isLoading: false,
        email: action.payload.email,
        groups: action.payload.groups,
        // token: action.payload.token,
        userId: action.payload.id
      };
    case GET_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null
      };
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
    case REGISTER_FAIL:
      // localStorage.removeItem("token");
      localStorage.removeItem("state");
      return {
        ...authInitState,
        // token: null
      };

    default:
      return state;
  }
};

export default authReducer;
