import React, { useEffect, useState } from "react";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/style.css";
import Select from "react-select";
import "./AddEmails.css";

export const accessOptions = [
  { value: "read", label: "View" },
  { value: "write", label: "Edit" },
];

interface IShareEmailModalProps {
  handleAddEmails: any;
}
const AddEmails: React.FC<IShareEmailModalProps> = (props: any) => {
  const [emails, setEmails] = useState<any>([]);
  const [access, setAccess] = useState("read");

  const handleAddBtn = () => {
    const lowerCaseEmails = emails.map((email: string) => email.toLowerCase());
    props.handleAddEmails(lowerCaseEmails, access);
    setAccess("read");
    setEmails([]);
  };

  return (
    <>
      <h3>Share With:</h3>
      <div className="add-emails-container">
        <ReactMultiEmail
          placeholder="Give Access By Email Address"
          emails={emails}
          onChange={(_emails: string[]) => {
            setEmails(_emails);
          }}
          validateEmail={(email) => {
            return isEmail(email); // return boolean
          }}
          getLabel={(
            email: string,
            index: number,
            removeEmail: (index: number) => void
          ) => {
            return (
              <div data-tag key={index}>
                {email}
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </span>
              </div>
            );
          }}
        />
        <Select
          className="react-select-email-container"
          classNamePrefix="react-select"
          options={accessOptions}
          isRtl={false}
          isSearchable={false}
          defaultValue={{ value: "read", label: "View" }}
          onChange={(e: any) => setAccess(e.value)}
          maxMenuHeight={200}
        />
      </div>
      <div className="add-emails-addBtn-container">
        <button className="add-emails-addBtn" onClick={handleAddBtn}>
          Add
        </button>
      </div>
    </>
  );
};

export default AddEmails