import React, { useEffect } from "react";
import Header from "../Header/Header";
import { CircularProgress } from "@material-ui/core";
import { RouteComponentProps } from "react-router";

const Oauth: React.FC<RouteComponentProps> = ({ history }) => {
  useEffect(() => {
    setTimeout(() => {
      history.push("/datasets");
    }, 200);
  }, []);

  return (
    <div>
      <CircularProgress />
    </div>
  );
};

export default Oauth;
